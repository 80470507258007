import React from "react"
import Layout from "../components/main/layout";
import Seo from '../components/main/seo';
import { StaticQuery, graphql, Link, withPrefix } from 'gatsby';
import { Helmet } from "react-helmet";


const Page404 = ({ pageContext, data }) => {

  const $metadata = data.strapiMetadata;
  const $mainNav = data.strapiMainNavigation;
  const $loginNav = data.strapiLoginNavigation;
  const $footerData = data.strapiFooterNavigation;
  const $socialsData = data.allStrapiSocial.nodes;

  return (


    <Layout mainNav={$mainNav} loginNav={$loginNav} footerData={$footerData} lang={"en"} socialData={$socialsData}>

      <Seo metadata={$metadata} is404={true}></Seo>

      <Helmet>
        <link href={withPrefix('assets/styles/404.inline.min.css')} rel="stylesheet" type="text/css" />
      </Helmet>

      <main>
        <div className="c-404 o-wrapper o-section">
          <h1 className="c-404__heading c-heading">
            <strong>404</strong>
            You went too far
          </h1>

          <p className="c-404__text u-text">Sure, we are eager to see your business grow beyond borders, but within our world.</p>
          <a href="/" className="c-404__link c-arrow-link u-tap-size">
            Go back
            <svg className="c-arrow-link__arrow" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
              <path d="m64.666 552.122h796.488l-377.914 372.014c-16.038 15.47-16.296 40.812-.58 56.602 15.72 15.788 41.464 16.048 57.504.572.194-.186.388-.376.58-.572l447.328-440.348c15.884-15.618 15.91-40.942.044-56.578-.01-.016-.02-.024-.044-.024l-447.328-440.349c-16.038-15.471-41.782-15.217-57.498.572-15.498 15.564-15.498 40.471 0 56.035l377.908 372.014-796.488-.002c-22.458 0-40.666 17.926-40.666 40.032 0 22.108 18.208 40.032 40.666 40.032z">
              </path>
            </svg>
          </a>
        </div>
      </main>

    </Layout>

  )

}


export const query = graphql`
  query NotFoundPageQuery ($lang: String)  {

  strapiMetadata (locale: {eq: $lang}) {
    title
    description
    avatar{
        url
    }
    
  },
  strapiMainNavigation (locale: {eq: $lang}) {
    links {
      title
      url
    }
  },
  strapiLoginNavigation (locale: {eq: $lang}) {
    links {
      url
      title
    }
  },
  strapiFooterNavigation (locale: {eq: $lang}) {
    Socials {
      title
    }
    Disclaimer {
      links {
        url
        title
      }
    }
    Link_group {
      link {
        title
        url
      }
    }
  },
  allStrapiSocial {
    nodes {
      slug
      link{
        title
        url
      }
      image{
        url
        width
        height
      }
    }
  },
}
`


export default Page404

